import { template as template_b3b1486d59234058b02d196e9353834a } from "@ember/template-compiler";
const FKControlMenuContainer = template_b3b1486d59234058b02d196e9353834a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
