import { template as template_4df5c3c1094f4038a1c721318a8a4ee8 } from "@ember/template-compiler";
const FKText = template_4df5c3c1094f4038a1c721318a8a4ee8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
