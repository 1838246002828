import { template as template_ee95822c0c8f42d68b40e44e3e0619ed } from "@ember/template-compiler";
import FKText from "discourse/form-kit/components/fk/text";
const FKFieldset = template_ee95822c0c8f42d68b40e44e3e0619ed(`
  <fieldset name={{@name}} class="form-kit__fieldset" ...attributes>
    {{#if @title}}
      <legend class="form-kit__fieldset-title">{{@title}}</legend>
    {{/if}}

    {{#if @description}}
      <FKText class="form-kit__fieldset-description">
        {{@description}}
      </FKText>
    {{/if}}

    {{yield}}
  </fieldset>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKFieldset;
