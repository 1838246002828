import { template as template_50af0b982d354b23a8773c3114df29b5 } from "@ember/template-compiler";
const FKLabel = template_50af0b982d354b23a8773c3114df29b5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
